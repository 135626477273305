import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StoreAuxService } from './../../services/storeAux/store-aux.service';
import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';
import {
  FeatureFlags,
  PaymentMethods,
  ResponsePaymentMethods,
  StandardResponse
} from '../definitions/payment-methods.interface';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodsService {
  email = localStorage.getItem('email');

  private readonly URL_CONFIG = '/adv-bbr-gateway-int/configuration';

  constructor(private readonly _http: HttpClient, private readonly _storeAuxService: StoreAuxService) {}

  getPaymentCards(test = false): Observable<ResponsePaymentMethods> {
    const email = localStorage.getItem('email');
    const params = new HttpParams({
      fromObject: Object.assign({ customer_id: email, test })
    });

    return this._http.get<ResponsePaymentMethods>(`${this._getCountryUrl()}/payments/payment-methods`, { params });
  }

  paymentSessions(
    firstName: string,
    lastName: string,
    email: string,
    test = false
  ): Observable<{ session: string; public_api_key: string }> {
    const params = new HttpParams({
      fromObject: Object.assign({ test })
    });
    return this._http.post<{ session: string; public_api_key: string }>(
      `${this._getCountryUrl()}/payments/payment-methods/enrollments/sessions`,
      { first_name: firstName, last_name: lastName, email, customer_id: email },
      { params }
    );
  }

  createPaymentMethod(session: string, token: string, status: string, test = false): Observable<PaymentMethods> {
    const email = localStorage.getItem('email');

    const params = new HttpParams({
      fromObject: Object.assign({ test })
    });

    return this._http.patch<PaymentMethods>(
      `${this._getCountryUrl()}/payments/payment-methods/enrollments/sessions/${session}`,
      {
        token,
        customer_id: email,
        status
      },
      { params }
    );
  }

  deletePaymentMethod(token: string, test = false): Observable<PaymentMethods> {
    const email = localStorage.getItem('email');
    const params = new HttpParams({
      fromObject: Object.assign({ customer_id: email, test })
    });
    return this._http.post<PaymentMethods>(
      `${this._getCountryUrl()}/payments/payment-methods/${token}/unenroll`,
      null,
      { params }
    );
  }

  getConfigurations(): Observable<FeatureFlags> {
    return this._http
      .get<StandardResponse<FeatureFlags>>(`${this._storeAuxService.getCountry(this.URL_CONFIG)}/feature-flags`)
      .pipe(map((response) => response.data));
  }

  private _getCountryUrl(): string {
    return `${this._storeAuxService.getCountry(environment.urlGatewayInt)}`;
  }
}
