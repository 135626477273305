import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../store/states/app.state';
import { StoreName } from '../../store/definitions/store.constants';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, concatMap, filter, map, takeUntil } from 'rxjs/operators';
import { ModulesService } from '../../services/modules/modules.service';
import { Action, Module } from '../../models/user/user.model';
import { SidenavService } from '../back-office/services/sidenav.service';
import { MenuService } from './menu.service';
import { MenuBlock, MenuItem } from './menu.model';
import { MENU_ITEMS, MENU_TIERS } from './menu.constant';
import { UserState } from '../../store/states/user.state';
import { CountryState } from '../../store/states/country.state';
import { UserType } from '../../permissions/definitions/permissions.enums';
import * as userActions from '../../store/actions/user.action';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { STORAGE_KEY } from './../../definitions/app.constants';
import { EncryptService } from './../../services/encrypt/encrypt.service';
import { EventsService } from '../../services/events/events.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
  collapsed = false;
  items: MenuBlock[];
  isMenuOpen = false;
  hasActiveModule: boolean;
  accountName: string;
  email: string;
  menuTiers = MENU_TIERS;
  urlNewModulesVisited: string[] = this.newModulesVisited;
  user: UserState;
  collapsedStatus: Observable<boolean> = this._menuService.collapsed;
  firstName: string;

  get newModulesVisited(): string[] {
    const urlModules = localStorage.getItem(STORAGE_KEY.urlNewModulesVisited);
    return urlModules ? this._encryptService.decryptObject(urlModules, STORAGE_KEY.urlNewModulesVisited) : [];
  }

  destroySubject$: Subject<void> = new Subject();

  constructor(
    private readonly _store: Store<AppState>,
    private readonly _menuService: MenuService,
    private readonly _sidenavService: SidenavService,
    private readonly _modulesService: ModulesService,
    public readonly _router: Router,
    private readonly _encryptService: EncryptService,
    private readonly _eventsService: EventsService
  ) {}

  ngOnInit() {
    this.getUserName();
    this.getUserModules();
    this.setAmplitudeUserId();
  }

  setAmplitudeUserId(isProd = environment.production): void {
    if (isProd) {
      this._eventsService.setUserId();
    }
  }

  getUserName() {
    this.firstName = localStorage.getItem(STORAGE_KEY.fullName).split(' ')[0];
  }

  getUserModules() {
    this._store
      .pipe(
        select(StoreName.country),
        filter(({ countrySelected }: CountryState) => Boolean(countrySelected)),
        concatMap(() => this._modulesService.getModules().pipe(map(({ data }: { data: Action }) => data as UserState))),
        takeUntil(this.destroySubject$),
        catchError(() => {
          this.items = [];
          return EMPTY;
        })
      )
      .subscribe((userState: UserState) => {
        this._store.dispatch(userActions.set({ payload: userState }));
        this.user = userState;
        this.accountName = userState.account_name;
        this.email = localStorage.getItem(STORAGE_KEY.email);
        this.items = this._menuService.getItems(MENU_ITEMS, userState.modules);
        this.collapsedStatus.pipe(takeUntil(this.destroySubject$)).subscribe((res: boolean) => {
          this.toggleMenu(res);
        });
        this.hasActiveModule = this.items.some((module: MenuBlock) => module.active);
        this.dispatchEventResize();

        if (userState.user_type !== UserType.admin && !this.hasToNavigate(userState)) {
          this._router.navigate([environment.modules.account]);
        }
      });
  }

  toggleMenu(status: boolean) {
    this.dispatchEventResize();
    this._sidenavService.toggleSidePanel();
    this.collapsed = status;
    this.items.forEach((item: MenuBlock) => item.modules.forEach((module: MenuItem) => (module.openMenu = false)));
    this.isMenuOpen = !status;
  }

  hasToNavigate(userState: UserState): boolean {
    const activeUrl = this._router.url.split('?')[0].substring(1);
    return userState.modules.map(({ slug }) => slug).some((slug) => activeUrl.includes(slug));
  }

  displaySubmenu(indexItem: number, indexModule: number) {
    if (this.isMenuOpen) {
      this.items[indexItem].modules[indexModule].openMenu = !this.items[indexItem].modules[indexModule].openMenu;
    } else {
      this._menuService.toggleMenu(false);
      this.items[indexItem].modules[indexModule].openMenu = true;
    }
  }

  dispatchEventResize() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 400);
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  someActiveModule(modules: MenuItem[]): boolean {
    return Boolean(modules?.some((module: MenuItem) => module.active));
  }

  addNewModuleVisited(item: MenuItem): void {
    const newModulesVisited = this.newModulesVisited;

    if (item.isNew && !newModulesVisited.includes(item.url)) {
      newModulesVisited.push(item.url);
      localStorage.setItem(
        STORAGE_KEY.urlNewModulesVisited,
        this._encryptService.encryptObject(newModulesVisited, STORAGE_KEY.urlNewModulesVisited)
      );
    }
  }

  emitAmplitudeEventMenuDesktop(nextUrl: string, currentUrl: string, isProduction = environment.production): void {
    const data = {
      ...{ os: this._eventsService.getOS() },
      ...this.user,
      ...{ isMobile: Boolean(this._eventsService.isMobile()) },
      ...{ id: localStorage.getItem(STORAGE_KEY.id) },
      ...{
        country: this._encryptService.decryptText(localStorage.getItem(STORAGE_KEY.country), STORAGE_KEY.country),
        ...{ email: localStorage.getItem(STORAGE_KEY.email) },
        ...{ nextUrl, currentUrl }
      }
    };

    const event = {
      COUNTRY: data.country,
      PAGE_NAME: data.nextUrl,
      SOURCE: data.currentUrl,
      USER_EMAIL: data.email,
      TIER: data.tier_name,
      TYPE: data.email.includes('@rappi') ? 'internal' : 'external',
      ACCOUNT_ID: data.id,
      TITLE: data.account_name,
      IS_MOBILE: data.isMobile,
      DEVICE_TYPE: data.os
    };

    if (isProduction) {
      this._eventsService.triggerAmplitudeEvent({
        EVENT_NAME: 'VIEW_PAGE_BBR',
        EVENT_PROPS: event
      });
    }
  }

  navigateLearning() {
    window.open('https://learning-brands.rappi.com.co/', '_blank');
  }
}
