export const rumAuth = 'RgUtNhZia5Tk3dVcwL_vMw';
export const rappiBaseUrl = {
  DEV: '//microservices.dev.rappi.com/api/',
  CO: '//services.grability.rappi.com/api/',
  MX: '//services.mxgrability.rappi.com/api/',
  BR: '//services.rappi.com.br/api/',
  AR: '//services.rappi.com.ar/api/',
  CL: '//services.rappi.cl/api/',
  PE: '//services.rappi.pe/api/',
  UY: '//services.rappi.com.uy/api/',
  EC: 'https://microservices.dev.rappi.com',
  CR: 'https://microservices.dev.rappi.com'
};
export const breadcrumbs = {
  mybrand: 'my brand',
  myBrandPlus: 'my brand plus',
  myRestaurant: 'my restaurant',
  sampling: 'sampling 360°',
  admin: 'admin',
  conversions: 'conversions',
  maps: 'maps',
  mapsDetails: 'details',
  mycompany: 'my company',
  deeplinks: 'DeepLinks Manager',
  amplify: 'Amplify Manager',
  campaigns: 'Ads campaigns',
  marketing: 'Marketing',
  seed: 'seed',
  approvals: 'promotion approval',
  powerShopper: 'powershopper',
  mycatalog: 'my catalog',
  subscriptions: 'subscriptions',
  mission: {
    value: 'mission-inventory',
    manager: 'manager',
    sanctions: 'sanction',
    report: 'report',
    management: 'Management'
  },
  iam: 'iam',
  masterData: {
    value: 'master data',
    productManagement: 'product management',
    approvals: 'approvals',
    entityManagement: 'entity management',
    createProduct: 'create product',
    bulkLoads: 'bulk loads',
    catalogManagement: 'catalog management'
  },
  analyze: {
    value: 'analyze',
    performance: 'Performance',
    rawData: 'Raw Data'
  },
  sense: {
    value: 'sense',
    behavior: 'Behavior',
    audiences: {
      value: 'Audiences',
      create: 'Create',
      management: 'Management'
    }
  },
  catalog: {
    value: 'Catalog',
    productsList: 'Products',
    productDetail: 'Product Detail',
    newProduct: 'New Product',
    editProduct: 'Edit Product'
  }
};
export const NoPhotoUrl = 'https://images.rappi.com/web/no-photos.svg?e=webp';
export const modules = {
  controlPanel: 'control-panel',
  audiences: 'marketing/audiences',
  mybrand: 'my-brand',
  myBrandPlus: 'my-brand-plus',
  context: 'context',
  consumerBehaviour: 'consumer-behaviour',
  insights: 'insights',
  myRestaurant: 'my-restaurant',
  myRestaurantContext: 'context',
  myRestaurantConsumerBehaviour: 'consumer-behaviour',
  myRestaurantOperational: 'operational',
  sampling: 'sampling-360',
  admin: 'admin',
  conversions: 'ads-conversion',
  checkouts: 'marketing/checkouts',
  maps: 'maps',
  mapsDetails: 'details',
  marketing: 'marketing',
  ads: 'ads',
  adsMfe: 'ads-mfe',
  amplifyMfe: 'amplify-mfe',
  deeplinksMfe: 'deeplinks',
  mycompany: 'my-company',
  amplify: 'amplify',
  dashboard: 'home-dashboard',
  analytics: 'analytics',
  analyzePerformance: 'performance',
  analyzeRawData: 'raw-data',
  shppperInsights: 'shopper-insights',
  senseBehaviour: 'behaviour',
  senseBehaviourSegment: 'behaviour',
  senseAudiences: 'audiences',
  deeplinks: 'marketing/deeplinks/manager',
  campaigns: 'marketing/ads/campaigns',
  promotions: 'promotions',
  seed: 'seed',
  approvals: 'promotion-approval',
  powerShopper: 'power-shopper',
  powerShopperCreate: 'creator',
  powerShopperDetails: 'details',
  mycatalog: 'my-catalog',
  subscriptions: 'subscriptions',
  missionInventory: 'mission-inventory',
  missionManager: 'mission-manager',
  missionSanctions: 'mission-sanction',
  missionReasons: 'mission-reason',
  missionAdjusts: 'inventory-fix',
  missionReport: 'mission-report',
  missionManagement: 'mission-management',
  permissions: 'permissions',
  masterData: 'master-data',
  mdEntityManager: 'entity-management',
  mdApprovals: 'approvals',
  mdBulkLoads: 'bulk-loads',
  mdCatalogManagement: 'catalog-management',
  samplingCore: 'sampling-ops',
  samplingCoreManagement: 'internal-tools/sampling-ops/management',
  samplingCoreOperator: 'internal-tools/sampling-ops/operator',
  samplingCoreWarehouses: 'internal-tools/sampling-ops/warehouses',
  samplingCoreSettings: 'internal-tools/sampling-ops/settings',
  samplingCoreControlPanel: 'internal-tools/sampling-ops/control-panel',
  samplingCorePricing: 'internal-tools/sampling-ops/pricing',
  samplingExt: 'sampling',
  settings: 'settings',
  createSamplingExt: 'create/sampling',
  createSamplingExtCampaignManager: 'create/sampling/campaign-manager',
  create: 'create',
  create2: 'create-2',
  createAds: 'create/ads',
  createDeeplinks: 'create/deeplinks',
  createAmplify: 'create/amplify',
  createTrade: 'create/trade',
  createReporting: 'create/reporting',
  createTradeCreateCampaign: 'create/trade/create-campaign',
  internalTools: 'internal-tools',
  account: 'account',
  session: 'session',
  tradeCore: 'trade',
  catalog: 'catalog',
  products: 'products',
  productsList: 'products-list',
  productDetail: 'product-detail',
  basicAnalysis: 'basic-analysis',
  audiencesMfe: 'audiences',
  requestHubMfe: 'request-hub',
  campaignManager: 'campaign-manager',
  campaignReports: 'campaign/reports',
  reportsMef: 'reports',
  reportsSmartAds: 'smart-ads',
  individualReport: 'individual-report',
  accountPaymentMethods: 'account/payment-methods'
};
