import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertsType } from '@rappi/ui/alerts';
import { matchFields } from '../../../core/landing/change-password/custom-validators';
import { PasswordValidatorConst } from '../../../models/user/user.model';
import { AlertsService } from '../../../services/alerts/alerts.service';
import { UsersService } from '../../../services/users/users.service';
import { EventsService } from './../../../services/events/events.service';
import { TriggerAmplitudeEntity } from './../../../services/events/event.interface';
import { ComponentsAmplitude } from '../../definitions/payment-methods.constants';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  readonly regexErrorMessage = PasswordValidatorConst.regexErrorMessage;

  form: FormGroup = new FormGroup(
    {
      current_password: new FormControl('', Validators.required),
      new_password: new FormControl('', [Validators.required, Validators.pattern(PasswordValidatorConst.regex)]),
      confirm_password: new FormControl('', [Validators.required, Validators.pattern(PasswordValidatorConst.regex)])
    },
    { validators: matchFields.bind(this, 'new_password', 'confirm_password') }
  );

  eyeSolidIcon = 'one-icon-eye-solid';
  eyeSolidSlashIcon = 'one-icon-eye-solid-slash';
  password = 'password';

  formFields = {
    current_password: {
      icon: this.eyeSolidIcon,
      type: this.password
    },
    new_password: {
      icon: this.eyeSolidIcon,
      type: this.password
    },
    confirm_password: {
      icon: this.eyeSolidIcon,
      type: this.password
    }
  };

  typeEvents = ComponentsAmplitude;

  constructor(
    private readonly _usersService: UsersService,
    private readonly _alertsService: AlertsService,
    private readonly _router: Router,
    private readonly _eventsService: EventsService
  ) {
    this.emitEventAmplitud('', this.typeEvents.viewPage, this.typeEvents.pageName, '/account/change-password');
  }

  get current_password(): AbstractControl {
    return this.form.get('current_password').value;
  }

  get new_password(): AbstractControl {
    return this.form.get('new_password').value;
  }

  onSubmit(f: FormGroup): void {
    if (f.invalid) {
      f.markAllAsTouched();
      return;
    }

    this.emitEventAmplitud('', this.typeEvents.selectBbrComponent, this.typeEvents.componentName, 'Change Password');

    this._usersService.changePassword(String(this.new_password), String(this.current_password)).subscribe(
      (res: { message: string }) => {
        this._alertsService.openAlerts(res.message, AlertsType.success);
        this._router.navigate(['/']);
      },
      ({ error: { message } }: HttpErrorResponse) => {
        this._alertsService.openAlerts(message, AlertsType.error);
      }
    );
  }

  showPassword(inputName: string): void {
    if (this.formFields[inputName].type === this.password) {
      this.formFields[inputName].type = 'text';
      this.formFields[inputName].icon = this.eyeSolidSlashIcon;
    } else {
      this.formFields[inputName].type = this.password;
      this.formFields[inputName].icon = this.eyeSolidIcon;
    }
  }

  emitEventAmplitud(path: string, name: string, component: string, url: string): void {
    this._eventsService.emitAmplitudeEvent({
      path,
      eventName: name,
      components: [{ componentName: component, url }]
    });
  }
}
