import { Injectable } from '@angular/core';
import { EventsService } from '../../../../services/events/events.service';
import { MenuContentMobileService } from '../../services/menu-content-mobile/menu-content-mobile.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    public readonly _eventsService: EventsService,
    public readonly menuContentService: MenuContentMobileService,
  ) { }
}
