import { STORAGE_KEY } from './../../definitions/app.constants';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { AppState } from '../../store/states/app.state';
import * as appSelectors from '../../store/selectors/app.selector';
import { EventDataLayer, TriggerAmplitudeEntity } from './event.interface';
import { BODY_REQUEST } from '@rappi/common';
import { EncryptService } from '../encrypt/encrypt.service';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  BODY_REQUEST = BODY_REQUEST;
  private readonly PREFIX = 'bbr_';

  private _gtmUserId: string;

  constructor(private readonly _store: Store<AppState>, private readonly _encryptService: EncryptService) {
    window['dataLayer'] = window['dataLayer'] || [];

    this._getGtmUserId();
  }

  send(category: string, action: string, label?: string): void {
    window['dataLayer'].push({ event: this.PREFIX + category, action, label: label || this._gtmUserId });
  }

  triggerAmplitudeEvent(trigger: TriggerAmplitudeEntity): void {
    this.dataLayerPush({
      event: 'triggerAmplitudeEvent',
      eventType: trigger.EVENT_NAME,
      eventProperties: trigger.EVENT_PROPS,
      userProperties: { USER_ID: this._gtmUserId }
    });
  }

  setUserId(gtmUserId = this._gtmUserId): void {
    this.dataLayerPush({
      event: 'amplitudeSetUserId',
      userId: gtmUserId
    });
  }

  dataLayerPush(event: EventDataLayer): void {
    try {
      window['dataLayer'].push(event);
    } catch (err) {}
  }

  getOS(userAgent = window.navigator.userAgent, platform = window.navigator.platform): string | null {
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  }

  isMobile(userAgent = navigator.userAgent, maxTouchPoints = navigator.maxTouchPoints): boolean {
    return (
      /Android|webOS|iPhone|iPad|iPod|IEMobile|Opera Mini/i.test(userAgent) ||
      (userAgent.match(/Mac/) && maxTouchPoints && maxTouchPoints > 2)
    );
  }

  emitAmplitudeEvent(options: {
    path: string;
    eventName: string;
    components: { componentName: string; url: string }[];
    errorMessage?: string;
  }): void {
    const data = {
      ...{ os: this.getOS() },
      ...(this.BODY_REQUEST.user as { tier_name: string; actions: { account_name: string } }),
      ...{ isMobile: Boolean(this.isMobile()) },
      ...{ id: localStorage.getItem(STORAGE_KEY.id) },
      ...{
        country: this._encryptService.decryptText(localStorage.getItem(STORAGE_KEY.country), STORAGE_KEY.country),
        ...{ email: localStorage.getItem(STORAGE_KEY.email) }
      }
    };

    const event = {
      PATH_ENDPOINT: options.path,
      COUNTRY: data.country,
      USER_EMAIL: data.email,
      TIER: data.tier_name,
      TYPE: data.email.includes('@rappi') ? 'internal' : 'external',
      ACCOUNT_ID: data.id,
      TITLE: data.actions.account_name,
      IS_MOBILE: data.isMobile,
      DEVICE_TYPE: data.os,
      PAGE_NAME: '',
      COMPONENT_NAME: '',
      MODAL_NAME: ''
    };

    options.components.forEach(({ componentName, url }) => {
      if (componentName) {
        event[componentName.toUpperCase()] = url;
      }
    });
    this.triggerAmplitudeEvent({
      EVENT_NAME: options.eventName.toUpperCase(),
      EVENT_PROPS: event
    });
  }

  private _getGtmUserId() {
    this._store.select(appSelectors.getUserAuthIdByCountry).subscribe((label: string) => {
      this._gtmUserId = label;
    });
  }
}
