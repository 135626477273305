<div class="payment-methods-wrapper">
  <one-card class="no-padding">
    <div class="title" one-card-title>
      <span>Payment methods</span>
      <button
        *ngIf="cards.length"
        class="rp-button rp-outlined rp-primary"
        (click)="addCard()"
        [disabled]="disabledCreate"
      >
        <em class="one-icon-add-material-v2"></em>Add new card
      </button>
    </div>
    <div one-card-content class="content-wrapper">
      <div class="info">
        <div class="title-info">
          <span>Manage your cards</span>
          <label *hasPermission="PermissionsPaymentMethods.TestPaymentMethods" class="switch">
            <p class="test">Test payment methods</p>
            <input type="checkbox" class="rp-switch" [(ngModel)]="inputValue" (click)="switchEnviroment()" />
          </label>
        </div>
        <div *ngIf="loading" class="spinner">
          <div class="rp-loader"></div>
        </div>
        <div *ngIf="!cards.length && !loading" class="actions">
          <img [src]="srcImgPreview" alt="preview" />
          <button class="rp-button rp-outlined rp-primary" (click)="addCard()" [disabled]="disabledCreate">
            <em class="one-icon-add-material-v2"></em>Add new card
          </button>
        </div>
        <div *ngIf="cards.length && !loading" class="list-cards">
          <div
            class="list-content"
            [class.disabled-content]="disabledStatus.includes(card?.status)"
            *ngFor="let card of cards"
          >
            <div class="card-content" [class.disabled-card]="card?.status === 'EXPIRED'">
              <img [src]="card.icon" alt="card-icon" />
              <div class="card-info">
                <span class="type-card">{{ transformType(card?.card_data?.type) }} *{{ card?.card_data?.lfd }}</span>
                <span class="name-card">{{ card?.card_data?.holder_name }}</span>
                <span class="exp-card"
                  >Exp {{ card?.card_data?.expiration_month }}/{{ card?.card_data?.expiration_year }}</span
                >
                <span
                  *ngIf="disabledStatus.includes(card?.status) || card?.status === 'EXPIRED'"
                  [class]="card?.status.toLowerCase()"
                >
                  {{ messagesStates[card?.status] }}
                </span>
              </div>
            </div>
            <div
              class="actions-card"
              [class.disabled-content]="disabledStatus.includes(card?.status)"
              (click)="deleteCard(card?.card_data, card?.vaulted_token_encrypted)"
            >
              <em class="one-icon-trash-outline"></em>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="sdk-root" class="content-yuno"></div>
  </one-card>
</div>
